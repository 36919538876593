import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusGlossaryCategory, GlossaryCategoryEdges } from './glossary-tab.interface';

export default (): GlossaryCategoryEdges[] => {
  const { allDirectusGlossaryCategory } = useStaticQuery<AllDirectusGlossaryCategory>(graphql`
    query {
      allDirectusGlossaryCategory(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            name
            letters
            items {
              heading
              content
              letter
              is_ad_wide
              ad_slug
            }
          }
        }
      }
    }
  `);

  return allDirectusGlossaryCategory.edges;
};
