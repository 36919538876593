import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Markdown from 'components/common/markdown/Markdown';
import AdditionalServiceAds from 'components/directus/additional-service-ads/AdditionalServiceAds';
import { getSolidFontAwesomeIcon } from 'utils/font-awesome/solid';
import { Props } from './GlossaryItem.interface';

import { glossaryItem__heading, glossaryItem__content } from './GlossaryItem.scss';

export default ({ item }: Props): JSX.Element => {
  const { heading, content, ad_slug, is_ad_wide } = item;
  return (
    <>
      <dt className={glossaryItem__heading}>{heading}</dt>
      <dd className={glossaryItem__content}>
        <Markdown
          source={content}
          container
          listItemIcon={
            <FontAwesomeIcon icon={getSolidFontAwesomeIcon('faChevronRight')} size="sm" aria-label="check icon." />
          }
        />
      </dd>
      {item.is_ad_wide && <AdditionalServiceAds slug={ad_slug} wide={is_ad_wide} />}
    </>
  );
};
