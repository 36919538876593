// extracted by mini-css-extract-plugin
export var additionalServices = "AdditionalServiceAds--additionalServices--AESuf";
export var additionalServices___bizik = "AdditionalServiceAds--additionalServices___bizik--kEzuT";
export var additionalServices___wide = "AdditionalServiceAds--additionalServices___wide--MvmCt";
export var additionalServices__button___blueGreen = "AdditionalServiceAds--additionalServices__button___blueGreen--0y2Gv";
export var additionalServices__button___bluePurple = "AdditionalServiceAds--additionalServices__button___bluePurple--4sY0s";
export var additionalServices__button___default = "AdditionalServiceAds--additionalServices__button___default--g7EG7";
export var additionalServices__button___pinkPurple = "AdditionalServiceAds--additionalServices__button___pinkPurple--XxkTq";
export var additionalServices__content = "AdditionalServiceAds--additionalServices__content--6ka0R";
export var additionalServices__icon = "AdditionalServiceAds--additionalServices__icon--4aIqQ";
export var additionalServices__inner = "AdditionalServiceAds--additionalServices__inner--KhukP";
export var additionalServices__left = "AdditionalServiceAds--additionalServices__left--w-KGi";
export var additionalServices__price = "AdditionalServiceAds--additionalServices__price--amMIU";
export var additionalServices__right = "AdditionalServiceAds--additionalServices__right--xrwT9";
export var additionalServices__service = "AdditionalServiceAds--additionalServices__service--l4B25";
export var tkMyriadProSemiCondensed = "AdditionalServiceAds--tk-myriad-pro-semi-condensed--nSkxC";