import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import slugify from 'react-slugify';
import 'react-tabs/style/react-tabs.css';

import GlossaryList from 'components/directus/glossary/list/GlossaryList';
import {
  glossaryTab,
  glossaryTab__tabs,
  glossaryTab__tab,
  glossaryTab__tab___active,
} from 'components/directus/glossary/Glossary.scss';
import glossaryTabHook from 'hooks/glossary/glossary-tab.hook';

export default (): JSX.Element => {
  const tabs = glossaryTabHook();
  const [tabIndex, updateTabIndex] = useState<number>(0);

  return (
    <>
      <div className={glossaryTab}>
        <Tabs selectedIndex={tabIndex} onSelect={(i) => updateTabIndex(i)}>
          <TabList className={glossaryTab__tabs}>
            {tabs.map(({ node }, i) => (
              <Tab
                key={`glossary-tab-${slugify(node.name)}`}
                className={`${glossaryTab__tab} ${tabIndex === i ? glossaryTab__tab___active : ''}`}
              >
                {node.name}
              </Tab>
            ))}
          </TabList>

          {tabs.map(
            ({ node }): JSX.Element => (
              <TabPanel key={`glossary-tab-content-${slugify(node.name)}`}>
                <GlossaryList name={node.name} letters={node.letters} items={node.items} />
              </TabPanel>
            )
          )}
        </Tabs>
      </div>
    </>
  );
};
